// 13 JAN 2022 Setup ReactJS AWS Amplify ChakraUI Eeslint Prettier
// Hai Tran
// tested
//
import './App.css';
import { useEffect, useState } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import LoginForm from './components/Login';
import HomePage from './components/Home'; 
import Amplify, {Auth} from 'aws-amplify';
import amplifyConfig from './aws-exports';
import { AuthContext } from './services/auth'; 

Amplify.configure(amplifyConfig);


function App({ signOut, user }) {
  return (
    <HomePage user={user} signOut={signOut}></HomePage>
  );
}


function AppCustom() {

  const [user, setUser] = useState(null)

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        setUser(user)
      } catch {
        console.log('unauthenticated')
      }
    }

    getUser()

  }, [])

  if (user) {
    return (
      <AuthContext.Provider value={{ user:user, setUser: setUser }}>
        <HomePage></HomePage> 
      </AuthContext.Provider>
    )
  }

  return (
    <AuthContext.Provider value={{user: user, setUser: setUser}}>
      <LoginForm></LoginForm>
    </AuthContext.Provider>
  );
}

export default withAuthenticator(App);
